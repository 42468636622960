// prettier-ignore
export const initWistiaVideo = (wistiaId, cb) => {
  if (typeof window !== 'undefined') {
    window.wistiaInitQueue = window.wistiaInitQueue || []
    window.wistiaInitQueue.push((W) => {
      // W.api() doesn't work right away for some reason
      (function loop() {
        const video = W.api(wistiaId)
        if (video && typeof cb === 'function') {
          cb(video)
        } else {
          setTimeout(loop, 100)
        }
      })()
    })
  }
}

export default initWistiaVideo
