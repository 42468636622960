import React, { useEffect, useState } from 'react'
import { initWistiaVideo } from '@helpers/init-wistia-video'
import { Image } from 'react-bootstrap'
import playSvg from '@images/play.png'


const WistiaLink = (props) => {
  const {wistiaId, thumbnail} = props
  const [initVideo, setInitVideo] = useState(null)

  useEffect(() => {
    !!initVideo && initVideo(wistiaId)
  }, [initVideo, wistiaId])

  const handleClick = (e) => {
    e.preventDefault()
    setInitVideo(() => {
      initWistiaVideo(wistiaId, (video) => {
        video.play()
      })
    })
  }

  return (
    <>
        <div className="video" onClick={handleClick}>
        <img className="video__thumbnail" alt="video thumbnail" src={thumbnail} />
        <div className="video__overlay"></div>
        <div className="video__play-wrapper">
          <Image fluid={true} src={playSvg} className="video__play" />
        </div>
      </div>
      <span
        id={wistiaId}
        style={{ height: 0, width: 0 }}
        className={`wistia_embed wistia_async_${wistiaId} popover=true popoverAnimateThumbnail=true`}
      />
    </>
  )
}

export default WistiaLink